<template>
  <main
    id="site-wrapper"
  >
    <section class="section">
      <div class="container-fluid">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{name: 'admin'}">
              Admin
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{name: 'operational-dashboard'}">
              Operational Dashboard
            </router-link>
          </li>
          <li class="breadcrumb-item active">
            {{ componentTitle | titleFormat }}
          </li>
        </ol>
        <div class="section-header">
          <div class="section-header-title">
            <router-link
              class="section-header-back"
              :to="{name: 'operational-dashboard'}"
            >
              <i class="fas fa-arrow-circle-left" />
            </router-link>
            <h1>
              {{ componentTitle | titleFormat }}
            </h1>
          </div>
        </div>

        <SelectDates
          v-if="areDatesLoaded"
          :initial-range="range"
          :updating="isUpdating"
          @get-data="getData"
        />

        <div class="table-wrapper">
          <div
            id="endpoints-table"
            class="section-panel"
          >
            <div class="section-panel-header">
              <div class="section-panel-header-title">
                <h3>{{ componentTitle | titleFormat }}</h3>
              </div>
              <div
                v-if="range.start"
                class="section-panel-header-action date-range"
              >
                {{ dateStart | dateFormat }} - {{ dateEnd | dateFormat }}
              </div>
            </div>
            <div class="section-panel-body">
              <div
                v-if="isLoading"
                class="isLoading"
              >
                loading data...
              </div>

              <div
                v-if="!isLoading"
              >
                <b-table
                  class="roomUsageTable"
                  striped
                  hover
                  responsive
                  sticky-header="true"
                  :items="tableDataSet"
                  :fields="tableDataFields"
                >
                  <template #cell(total_duration)="row">
                    {{ row.item.total_duration | HoursMinsSecs }}
                  </template>

                  <template #cell(average_duration)="row">
                    {{ row.item.average_duration | HoursMinsSecs }}
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {meetingStore} from '@/store/__STORE_meetings';
import ChartDataMixin from '@/mixins/ChartDataMixin';
import DateFormatMixin from '@/mixins/DateFormatMixin';
import OpsDashMixin from '@/mixins/OpsDashMixin';

import SelectDates from './components/SelectDates.vue';

export default {
  name: 'RoomUsageDrilldown',
  components: {
    SelectDates,
  },
  mixins: [
    ChartDataMixin,
    DateFormatMixin,
    OpsDashMixin,
  ],
  data() {
    return {
      componentTitle: this.$route.name,
      chartWidth: 100,
      tableDataFields: [
        {
          key: 'alias',
          label: 'Room name',
          class: 'roomName',
          stickyColumn: true,
        },
        {
          key: 'conferences',
          label: 'Conferences',
          headerAbbr: 'Number of Conferences',
        },
        {
          key: 'total_duration',
          label: 'Tot Duration',
          headerAbbr: 'Total Duration',
        },
        {
          key: 'average_duration',
          label: 'Avg Duration',
          headerAbbr: 'Average Duration',
        },
        {
          key: 'max_participants',
          label: 'Max Participants',
          headerAbbr: 'Maximum Participants',
        },
        {
          key: 'average_participants',
          label: 'Avg Participants',
          headerAbbr: 'Average Participants',
        },
        {
          key: 'last_activity',
          label: 'Last Activity',
        },
      ],
      tableDataSet: [],
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'DD-MM-YYYY',
      },
      attributes: [],
      polling: null,
      isUpdating: false,
    };
  },
  computed: {
    isLoading() {
      return (!this.roomUsage.rooms ? true : false);
    },
    areDatesLoaded() {
      return this.range.start && this.range.end;
    },
    tableData() {
      return this.roomUsage.rooms;
    },
    dateStart() {
      return this.roomUsage.from_date;
    },
    dateEnd() {
      return this.roomUsage.to_date;
    },
    dateRange() {
      return this.range;
    },
    roomUsage() {
      return meetingStore.roomUsage;
    },
  },
  watch: {
    tableData: function() {
      this.isUpdating = false;
      this.tableDataSet = this.roomUsage.rooms;
      this.range.start = this.formatDateYMD(this.roomUsage.from_date);
      this.range.end = this.formatDateYMD(this.roomUsage.to_date);
    },
  },
  created() {
    this.getData();
    this.pollData();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    getData(start, end) {
      if (start || end) {
        this.updateDates(start, end);
      }
      this.isUpdating = true;
      meetingStore.getRoomUsageData(this.createDates(this.range.start, this.range.end));
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/__variables";

  .container-fluid {
    max-width: $container-width;
  }

  .section-panel {
    display: flex;
    flex-direction: column;

    .isLoading {
      align-items: center;
      display: flex;
      flex: 1 0 auto;
      justify-content: center;
      padding: 2rem;
    }
  }

  .table-wrapper {
    margin-top: 1rem;
  }

  ::v-deep .table > tbody > tr > td {
    vertical-align: middle;
  }

  .roomUsageTable ::v-deep .roomName {
    font-weight: bold;
    white-space: nowrap;
  }

  .roomUsageTable ::v-deep td {
    padding: 1.275rem;
  }
</style>
